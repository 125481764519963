export default ({ signatureLink, isSandbox, holder }) => ({
  yousign: null,
  showSuccessMessage: false,
  redirectUrl: '',

  init() {
    this.yousign = new Yousign({
      signatureLink,
      isSandbox,
      iframeContainerId: 'iframe-container',
      iFrameAttributes: {
        referrerPolicy: 'origin-when-cross-origin',
      },
    });

    this.yousign.onStarted(data => this.handleSigningStatusChanged(data));
    this.yousign.onError(data => this.handleSigningStatusChanged(data));
    this.yousign.onDeclined(data => this.handleSigningStatusChanged(data));

    this.yousign.onSuccess(data => this.handleSigningStatusChanged(data));
    this.yousign.onSignatureDone(data => this.handleSigningStatusChanged(data));
  },

  async handleSigningStatusChanged({ signer_id, signature_request_id, event }, followRedirect = false) {
    const url = route('yousign.signatures.signers.status.update', {
      signature: signature_request_id,
      signee: signer_id,
      holder,
      event,
    });

    if (event === 'success' || event === 'signature.done') {
      this.showSuccessMessage = true;
    }

    try {
      const response = await window.axios.put(url);

      if (response.data.redirect_url) {
        this.redirectUrl = response.data.redirect_url;
        if (followRedirect) {
          window.location.href = response.data.redirect_url;
        }
      }
    } catch (error) {
    }
  },
});
